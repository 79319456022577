import { useEffect, useCallback, useRef, useMemo } from 'react';
import { doesIdentifierMatchKeyboardEvent } from 'utils/doesIdentifierMatchKeyboardEvent';

const defaultOptions = {
  eventTypes: ['keydown'],
  when: true,
};

function useKey(input, callback, options_) {
  const keyList = useMemo(() => {
    if (Array.isArray(input)) {
      return input;
    } else {
      return [input];
    }
  }, [input]);
  const options = Object.assign({}, defaultOptions, options_);
  const { when, eventTypes } = options;
  const callbackRef = useRef(callback);
  const { target } = options;

  useEffect(() => {
    callbackRef.current = callback;
  });

  const handle = useCallback(
    e => {
      if (keyList.some(identifier => doesIdentifierMatchKeyboardEvent(e, identifier))) {
        callbackRef.current(e);
      }
    },
    [keyList]
  );

  useEffect(() => {
    if (when && typeof window !== 'undefined') {
      const targetNode = target ? target.current : window;
      eventTypes.forEach(eventType => {
        targetNode && targetNode.addEventListener(eventType, handle);
      });

      return () => {
        eventTypes.forEach(eventType => {
          targetNode && targetNode.removeEventListener(eventType, handle);
        });
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [when, eventTypes, keyList, target, callback]);
}

export default useKey;
