import { keyframes, css } from 'styled-components/macro';

// Media query breakpoints
export const media = {
  desktop: 1600,
  laptop: 1280,
  tablet: 1024,
  mobile: 696,
  mobileSmall: 320,
  mobileLS: `(max-width: 820px) and (max-height: 420px)`,
};

// Animation utils
export const AnimFade = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// Offset content padding based on the header nav
export const sectionPadding = css`
  padding-right: var(--space5XL);
  padding-left: calc(var(--space4XL) * 2);

  @media (min-width: ${media.desktop}px) {
    padding-left: var(--space5XL);
  }

  @media (max-width: ${media.tablet}px) {
    padding-left: calc(var(--space4XL) + var(--space3XL));
  }

  @media (max-width: ${media.mobile}px) {
    padding-right: var(--spaceL);
    padding-left: var(--spaceL);
  }

  @media (max-width: ${media.mobile}px), (max-height: ${media.mobile}px) {
    padding-left: var(--spaceOuter);
    padding-right: var(--spaceOuter);
  }

  @media ${media.mobileLS} {
    padding-left: var(--space4XL);
    padding-right: var(--space4XL);
  }
`;

// Clip path to create angled corners
export function cornerClip(size = 8) {
  return css`
    clip-path: polygon(
      0 0,
      100% 0,
      100% calc(100% - ${size}px),
      calc(100% - ${size}px) 100%,
      0 100%
    );
  `;
}

/**
 * Convert a px string to a number
 */
export const pxToNum = px => Number(px.replace('px', ''));

/**
 * Convert a number to a px string
 */
export const numToPx = num => `${num}px`;

/**
 * Convert pixel values to rem for a11y
 */
export const pxToRem = px => `${px / 16}rem`;

/**
 * Convert ms token values to a raw numbers for ReactTransitionGroup
 * Transition delay props
 */
export const msToNum = msString => Number(msString.replace('ms', ''));

/**
 * Convert a number to an ms string
 */
export const numToMs = num => `${num}ms`;

/**
 * Convert an rgb theme property (e.g. rgbBlack: '0 0 0')
 * to values that can be spread into a ThreeJS Color class
 */
export const rgbToThreeColor = rgb => rgb.split(' ').map(value => Number(value) / 255);
