import React, {
  createContext,
  Fragment,
  lazy,
  Suspense,
  useEffect,
  useReducer,
} from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import {
  Transition,
  config as transitionConfig,
  TransitionGroup,
} from 'react-transition-group';
import styled, { createGlobalStyle, css, ThemeProvider } from 'styled-components/macro';

import { initialState, reducer } from 'app/reducer';
import { createThemeProperties, msToNum, theme, tokens } from 'app/theme';
import GothamBook from 'assets/fonts/gotham-book.woff2';
import GothamMedium from 'assets/fonts/gotham-medium.woff2';
import Cursor from 'components/cursor';
import Header from 'components/Header';
import { useLocalStorage, usePrefersReducedMotion } from 'hooks';
import prerender from 'utils/prerender';
import { cornerClip, media } from 'utils/style';
import { reflow } from 'utils/transition';

const Home = lazy(() => import('pages/Home'));
const Contact = lazy(() => import('pages/Contact'));
const Easypharm = lazy(() => import('pages/EasyPharm'));
const Projects = lazy(() => import('pages/Projects'));
const NotFound = lazy(() => import('pages/404'));
const TwoZeroFourEight = lazy(() => import('easterEggs/twoZeroFourEight'));

export const AppContext = createContext();
export const TransitionContext = createContext();

const repoPrompt = `\u00A9 May 2020-${new Date().getFullYear()} Ritesh Bucha\n\n`;

export const fontStyles = `
  @font-face {
    font-family: "Gotham";
    font-weight: 400;
    src: url(${GothamBook}) format("woff");
    font-display: swap;
  }
  @font-face {
    font-family: "Gotham";
    font-weight: 500;
    src: url(${GothamMedium}) format("woff2");
    font-display: swap;
  }
`;

function App() {
  const [storedTheme] = useLocalStorage('theme', 'dark');
  const [state, dispatch] = useReducer(reducer, initialState);
  const prefersReducedMotion = usePrefersReducedMotion();
  const { currentTheme } = state;

  const disableConsole = () => {
    console.log(`
    _   _                _        ____            _

  ██████╗░██╗░░░██╗░█████╗░██╗░░██╗░█████╗░██████╗░██╗████████╗███████╗░██████╗██╗░░██╗
  ██╔══██╗██║░░░██║██╔══██╗██║░░██║██╔══██╗██╔══██╗██║╚══██╔══╝██╔════╝██╔════╝██║░░██║
  ██████╦╝██║░░░██║██║░░╚═╝███████║███████║██████╔╝██║░░░██║░░░█████╗░░╚█████╗░███████║
  ██╔══██╗██║░░░██║██║░░██╗██╔══██║██╔══██║██╔══██╗██║░░░██║░░░██╔══╝░░░╚═══██╗██╔══██║
  ██████╦╝╚██████╔╝╚█████╔╝██║░░██║██║░░██║██║░░██║██║░░░██║░░░███████╗██████╔╝██║░░██║
  ╚═════╝░░╚═════╝░░╚════╝░╚═╝░░╚═╝╚═╝░░╚═╝╚═╝░░╚═╝╚═╝░░░╚═╝░░░╚══════╝╚═════╝░╚═╝░░╚═╝
    _   ___         _       ____     ___     ___   _

                                                             `);

    console.log(
      "%c Let's talk, head to contact page and schedule a call with me!",
      'background: #212121; color: #f69510; padding: 6px;'
    );

    console.log = () => {};
    console.error = () => {};
  };

  useEffect(() => {
    if (prefersReducedMotion) {
      transitionConfig.disabled = true;
    } else {
      transitionConfig.disabled = false;
    }
  }, [prefersReducedMotion]);

  useEffect(() => {
    if (!prerender) {
      console.info(`%c${repoPrompt}`, 'font-size: 18px');
      document.body.removeAttribute('class');
      const markupComment = document.createComment(repoPrompt);
      document.documentElement.prepend(markupComment);
      // disableConsole();
    }

    window.history.scrollRestoration = 'manual';
  }, []);

  useEffect(() => {
    dispatch({ type: 'setTheme', value: theme[storedTheme] });
  }, [storedTheme]);

  return (
    <HelmetProvider>
      <ThemeProvider theme={currentTheme}>
        <AppContext.Provider value={{ ...state, dispatch }}>
          <BrowserRouter>
            <Cursor />
            <AppRoutes />
          </BrowserRouter>
        </AppContext.Provider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

function AppRoutes() {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={`https://bucharitesh.in${pathname}`} />
        <link rel="preload" href={GothamBook} as="font" crossorigin="" />
        <link rel="preload" href={GothamMedium} as="font" crossorigin="" />
        <style>{fontStyles}</style>
      </Helmet>
      <GlobalStyles />
      <SkipToMain href="#MainContent">Skip to main content</SkipToMain>
      <Header location={location} />
      <TransitionGroup
        component={AppMainContent}
        tabIndex={-1}
        id="MainContent"
        role="main"
      >
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <AppPage status={status}>
                <Suspense fallback={<Fragment />}>
                  <Switch location={location}>
                    <Route exact path="/" component={Home} />
                    <Route path="/contact" component={Contact} />
                    <Route exact path="/projects" component={Projects} />
                    <Route exact path="/projects/EasyPharm" component={Easypharm} />
                    <Route exact path="/TwoZeroFourEight" component={TwoZeroFourEight} />
                    <Route component={NotFound} />
                  </Switch>
                </Suspense>
              </AppPage>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
}

export const GlobalStyles = createGlobalStyle`
  :root {
    ${createThemeProperties(tokens.base)}

    @media (max-width: ${media.laptop}px) {
      ${createThemeProperties(tokens.laptop)}
    }

    @media (max-width: ${media.tablet}px) {
      ${createThemeProperties(tokens.tablet)}
    }

    @media (max-width: ${media.mobile}px) {
      ${createThemeProperties(tokens.mobile)}
    }

    @media (max-width: ${media.mobileSmall}px) {
      ${createThemeProperties(tokens.mobileSmall)}
    }
  }

  .dark {
    ${createThemeProperties(theme.dark)}
  }

  .light {
    ${createThemeProperties(theme.light)}
  }

  ${
    !prerender &&
    css`
      body.light,
      body.dark,
      body {
        ${props => createThemeProperties(props.theme)}
      }
    `
  }

  html,
  body {
    box-sizing: border-box;
    font-family: var(--fontStack);
    font-weight: var(--fontWeightRegular);
    background: rgb(var(--rgbBackground));
    color: var(--colorTextBody);
    border: 0;
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    cursor: none;
    scroll-behavior: smooth;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  ::selection {
    background: rgb(var(--rgbAccent));
    color: rgb(var(--rgbBlack));
  }

  #root *,
  #root *::before,
  #root *::after {
    @media (prefers-reduced-motion: reduce) {
      animation-duration: 0s;
      animation-delay: 0s;
      transition-duration: 0s;
      transition-delay: 0s;
    }
  }

  .cursor {
    width: 20px;
    height: 20px;
    position: absolute;
    border-radius: 50%;
    pointer-events: none;
    background-color: #82F9A1;
    opacity: 0.5;
    z-index: 999;
  }

  @keyframes cursorAnim {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(5);
    }
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
  .expand {
    animation: cursorAnim 0.5s forwards;
  }

`;

const AppMainContent = styled.main`
  width: 100%;
  overflow-x: hidden;
  position: relative;
  background: rgb(var(--rgbBackground));
  transition: background var(--durationM) ease;
  outline: none;
  display: grid;
  grid-template: 100% / 100%;
`;

const AppPage = styled.div`
  overflow-x: hidden;
  opacity: 0;
  grid-area: 1 / 1;
  transition: opacity var(--durationS) ease;

  ${props =>
    (props.status === 'exiting' || props.status === 'entering') &&
    css`
      opacity: 0;
    `}

  ${props =>
    props.status === 'entered' &&
    css`
      transition-duration: var(--durationL);
      transition-delay: var(--durationXS);
      opacity: 1;
    `}
`;

const SkipToMain = styled.a`
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  color: rgb(var(--rgbBackground));
  background: rgb(var(--rgbPrimary));
  z-index: 99;

  &:focus {
    padding: var(--spaceS) var(--spaceM);
    position: fixed;
    top: var(--spaceM);
    left: var(--spaceM);
    clip: auto;
    width: auto;
    height: auto;
    text-decoration: none;
    font-weight: var(--fontWeightMedium);
    line-height: 1;
    ${cornerClip(8)}
  }
`;

export default App;
